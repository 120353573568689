import React from 'react'
import { HealthData } from '../HealthDataTable'
import { HealthDataModelType } from '../globaltypes'
import OverlayContainer from './OverlayContainer'

const ContentsView = ({
	contents,
	context,
	onClose,
}: {
	contents: HealthData[] | undefined
	context: string
	onClose: () => void
}) => {
	let title
	switch (context) {
		case HealthDataModelType.Observation:
			title = 'Belongs to group(s):'
			break
		case HealthDataModelType.Group:
			title = 'Observations:'
			break
		case HealthDataModelType.Concept:
			alert('WIP')
			break
	}
	return (
		<OverlayContainer>
			<h3>{title}</h3>
			<ul>
				{contents && contents.length ? (
					contents.map((elm, i) => {
						return (
							<li
								key={`group_observation_${i}`}
							>{`${elm.code?.toString().toUpperCase()} - ${elm.title}`}</li>
						)
					})
				) : (
					<li>* None *</li>
				)}
			</ul>

			<button onClick={() => onClose()}>Close</button>
		</OverlayContainer>
	)
}
export default ContentsView
