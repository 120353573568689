import React from 'react'
import {
	State,
	TRelationalDataHandlers,
} from '../../../../hooks/useHandleRelationalData'
import styled, { css } from 'styled-components'
import { GroupData } from '../../../../screens/HealthData/Group'
import { ObservationData } from '../../../../screens/HealthData/Observation'
import SearchInput from '../../SearchInput'
import { HealthDataModelType } from '../../globaltypes'
import RelationalItem from './RelationalItem'

const cssStyle = css`
	.content-type-h4 {
		color: red;
	}

	.group-title-button {
		display: flex;
		align-items: center;
	}

	.group-search-view-button {
		height: 2em;
		margin: 0 1em;
	}

	button {
		border: 1px solid black;
		border-radius: 3px;
	}
`

const Styler = styled.article`
	${cssStyle}
`

const RelationalUpdater = ({
	updateHandlers,
	obsList = [],
	grpList = [],
	state,
	context,
}: {
	updateHandlers: TRelationalDataHandlers
	obsList?: ObservationData
	grpList?: GroupData
	state: State
	context: HealthDataModelType
}) => {
	const { addObservation, removeObservation, addGroup, removeGroup } =
		updateHandlers
	const { observations, groups } = state

	if (context === HealthDataModelType.Observation)
		return (
			<Styler>
				<hr />
				<h4 className={'content-type-h4'}>Assign to group(s):</h4>
				<SearchInput addItem={addGroup} searchItems={grpList} list={groups} />
				<div>
					{groups.length ? (
						groups.map((group) => (
							<RelationalItem
								key={`${group.code}`}
								contentItem={group}
								removeItem={removeGroup}
							/>
						))
					) : (
						<p>* None *</p>
					)}
				</div>
			</Styler>
		)

	if (context === HealthDataModelType.Group)
		return (
			<Styler>
				<hr />
				<h4 className={'content-type-h4'}>Observations:</h4>
				<SearchInput
					addItem={addObservation}
					searchItems={obsList}
					list={observations}
				/>
				<div>
					{observations?.map((observation) => (
						<RelationalItem
							key={`${observation.code}`}
							contentItem={observation}
							removeItem={removeObservation}
						/>
					))}
				</div>
			</Styler>
		)

	if (context === HealthDataModelType.Concept)
		alert('Concept view not yet built')

	return <p>Error occurred, whilst loading contents</p>
}

export default RelationalUpdater
