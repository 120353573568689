import * as React from 'react'
import PageMenu from './PageMenu'
import Observation, { ObservationData } from './Observation'
import { Route, Switch } from 'react-router-dom'
import Group, { GroupData } from './Group'
import Concept from './Concept'
import useFetch, { CachePolicies } from 'use-http'
import { useCallback, useEffect, useState } from 'react'

/*
 * This is the main component for the HealthData screen
 * The names follows the FHIR standard for objects in healthdata
 * See https://www.hl7.org/fhir/ for more information
 */

const HealthData: React.FC = () => {
	const [observations, setObservations] = useState<ObservationData>([])
	const [groups, setGroups] = useState<GroupData>([])
	const [requestResult, setRequestResult] = useState('')

	const { request: fetchRequest, error } = useFetch(
		`${process.env.REACT_APP_SURVEY_API_URL}`,
		{
			cachePolicy: CachePolicies.NO_CACHE,
		}
	)

	const sortHealthData = <T extends DefaultEntry>(data: T[]): T[] => data.sort((a: T, b: T) => a.code.localeCompare(b.code))

	const getObservations = useCallback(async (): Promise<ObservationData> => {
		try {
			const result = await fetchRequest.get('api/healthdata/observations')
			const data: ObservationData = result
			if (data.length > 0) {
				return sortHealthData(data)
			}
			return data
		} catch (error) {
			return []
		}
	}, [fetchRequest])

	const getGroups = useCallback(async (): Promise<GroupData> => {
		try {
			const result = await fetchRequest.get('api/healthdata/groups')
			const data: GroupData = result
			if (data.length > 0) {
				return sortHealthData(data)
			}
			return data
		} catch (error) {
			return []
		}
	}, [fetchRequest])

	useEffect(() => {
		const fetchData = async () => {
			const observations = await getObservations()
			setObservations(observations)
			const groups = await getGroups()
			setGroups(groups)
		}
		fetchData()
	}, [getObservations, getGroups, requestResult])

	return (
		<>
			<PageMenu />
			<Switch>
				<Route path='/healthdata/observations'>
					<Observation
						observations={observations}
						groups={groups}
						setRequestResult={setRequestResult}
						setObservations={setObservations}
						sortObservations={sortHealthData}
						error={error}
					/>
				</Route>
				<Route path='/healthdata/group'>
					<Group
						observations={observations}
						groups={groups}
						setRequestResult={setRequestResult}
						setGroups={setGroups}
						sortGroups={sortHealthData}
						error={error}
					/>
				</Route>
				<Route path='/healthdata/concept'>
					<Concept />
				</Route>
			</Switch>
		</>
	)
}

export type DefaultEntry = {
	id: number
	code: string
	title: string
	interval: number
	category?: string
}
export default HealthData
