import { HealthData } from './HealthDataTable'
import {
	ChangeEvent,
	FC,
	useCallback,
	useEffect,
	useState,
} from 'react'
import { css } from 'styled-components/macro'
import addNew from '../../images/addNew.svg'
import deleteIcon from '../../images/delete-red.svg'
import { capitalize } from 'lodash'
import React from 'react'

const iconStyle = css`
	display: block;
	background-size: cover;
	width: 20px;
	height: 20px;
	border: none;
`

const removeBtnStyle = css`
	display: block;
	background-size: cover;
	background-color: transparent;
	width: 20px;
	height: 20px;
	border: none;
	margin-left: 5px;
`

export enum Outcome {
	Good = 'good',
	Borderline = 'borderline',
	Bad = 'bad',
}

const ThresholdInput: FC<ThresholdInputProps> = ({
	threshold,
	index,
	addThresholdEntry,
	removeThresholdEntry,
}) => {
	const handleAddThresholdEntry = (
		key: string,
		event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
	) => addThresholdEntry(key, index, event)

	const currentOutcome = threshold?.outcome?.toString().toLocaleLowerCase() ?? ''

	return (
		<>
			<label htmlFor='description' key='label_description'>
				Description:
				<input
					type='text'
					id='description'
					onChange={(event: ChangeEvent<HTMLInputElement>) =>
						handleAddThresholdEntry('description', event)
					}
					value={
						threshold && typeof threshold.description === 'string'
							? threshold.description
							: ''
					}
				/>
			</label>
			<div
				className='threshold'
				key={`threshold_${threshold ? threshold.id : index}`}
				css='.thresholdMinMax{width: 70px;}'
			>
				<label htmlFor='min' key='label_min'>
					Min:
					<input
						type='number'
						id='min'
						className='thresholdMinMax'
						onChange={(event: ChangeEvent<HTMLInputElement>) =>
							handleAddThresholdEntry('min', event)
						}
						step='0.01'
						value={
							threshold && typeof threshold.min === 'number'
								? threshold.min
								: ''
						}
					/>
				</label>
				<label htmlFor='max' key='label_max'>
					Max:
					<input
						type='number'
						id='max'
						className='thresholdMinMax'
						onChange={(event: ChangeEvent<HTMLInputElement>) =>
							handleAddThresholdEntry('max', event)
						}
						step='0.01'
						value={
							threshold && typeof threshold.max === 'number'
								? threshold.max
								: ''
						}
					/>
				</label>
				<label htmlFor='outcome' key='label_outcome'>
					Outcome:
					<select
						id='outcome'
						onChange={(event: ChangeEvent<HTMLSelectElement>) =>
							handleAddThresholdEntry('outcome', event)
						}
						value={currentOutcome}
					>
						<option></option>
						<option value={Outcome.Bad}>{capitalize(Outcome.Bad)}</option>
						<option value={Outcome.Borderline}>
							{capitalize(Outcome.Borderline)}
						</option>
						<option value={Outcome.Good}>{capitalize(Outcome.Good)}</option>
					</select>
				</label>
				{threshold && (
					<button
						title='remove threshold'
						type='button'
						css={css`
							${removeBtnStyle}
						`}
						onClick={() => removeThresholdEntry(index)}
					>
						<span
							css={`
								${iconStyle} background-image: url(${deleteIcon});
								:hover {
									cursor: pointer;
								}
							`}
						></span>
					</button>
				)}
			</div>
		</>
	)
}

const ThresholdInputs: FC<ThresholdInputsProps> = ({
	thresholds,
	thresholdCount,
	addThreshold,
	...rest
}) => {
	const [isPreviousFilled, setIsPreviousFilled] = useState(false)

	const handleIsPreviousFilled = useCallback(() => {
		const lastThreshold = thresholds[thresholds.length - 1]

		if (lastThreshold === null || lastThreshold === undefined) {
			return false
		}

		const isFilled = !Object.entries(lastThreshold)
			.filter(([key, _]) => ['description', 'outcome'].includes(key))
			.map(([_, value]) => value)
			.includes(undefined)

		const descriptionVal = Object.entries(lastThreshold).find(
			([key]) => key === 'description'
		)?.[1]
		const descriptionNotEmpty =
			descriptionVal !== undefined && descriptionVal !== ''

		const validated = isFilled && descriptionNotEmpty

		if (validated !== isPreviousFilled) {
			setIsPreviousFilled(validated)
		}
	}, [isPreviousFilled, thresholds])

	useEffect(() => {
		if (thresholds.length) {
			handleIsPreviousFilled()
		}
	}, [thresholds, handleIsPreviousFilled])

	return (
		<>
			{thresholds.map((_, index) => {
				return (
					<ThresholdInput
						key={`thresholdElement_${thresholds && thresholds[index] && thresholds[index].id ? thresholds[index].id : thresholdCount + index}`}
						threshold={thresholds[index]}
						thresholdCount={thresholdCount}
						index={index}
						{...rest}
					/>
				)
			})}

			{isPreviousFilled && (
				<button
					title='addBtn'
					type='button'
					css={css`
						background: none;
						border: none;
					`}
					onClick={() => {
						addThreshold()
						setIsPreviousFilled(false)
					}}
				>
					<span
						css={`
							${iconStyle} background-image: url(${addNew});
							:hover {
								cursor: pointer;
							}
						`}
					></span>
				</button>
			)}
		</>
	)
}

const Threshold: FC<ThresholdProps> = ({
	addThreshold,
	removeThreshold,
	updateThreshold,
	thresholds,
}) => {
	const thresholdCount = thresholds.length
	const addThresholdEntry = useCallback(
		(
			key: string,
			index: number,
			event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
		) => {
			if (!['description', 'min', 'max', 'outcome'].includes(key)) return

			const inputValue =
				key === 'min' || key === 'max'
					? parseFloat(event.target.value)
					: event.target.value

			const updatedThresholds = [...thresholds]

			let updatedThreshold = {
				...updatedThresholds[index],
				[key]: inputValue ?? event.target.value,
			}

			const keyVals = Object.entries(updatedThreshold)
			if (!keyVals.some(([key]) => key === 'outcome')) {
				keyVals.push(['outcome', ''])
				updatedThreshold = Object.fromEntries(keyVals)
			}

			updatedThresholds[index] = updatedThreshold

			updateThreshold({ threshold: { ...updatedThreshold }, index })
			return updatedThresholds
		},
		[updateThreshold, thresholds]
	)

	const removeThresholdEntry = useCallback(
		(index: number) => removeThreshold(index),
		[removeThreshold]
	)

	return (
		<ThresholdInputs
			thresholds={thresholds}
			removeThresholdEntry={removeThresholdEntry}
			addThresholdEntry={addThresholdEntry}
			addThreshold={addThreshold}
			thresholdCount={thresholdCount}
		/>
	)
}

export default Threshold

interface ThresholdProps {
	addThreshold: () => void
	removeThreshold: (index: number) => void
	updateThreshold: (item: { threshold: HealthData; index: number }) => void
	thresholds: HealthData[]
}

interface ThresholdInputsProps {
	threshold?: HealthData
	thresholds: HealthData[]
	thresholdCount: number
	removeThresholdEntry: (index: number) => void
	addThresholdEntry: (
		key: string,
		index: number,
		event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
	) => void
	addThreshold: () => void
}

interface ThresholdInputProps {
	threshold?: HealthData
	thresholdCount: number
	index: number
	removeThresholdEntry: (index: number) => void
	addThresholdEntry: (
		key: string,
		index: number,
		event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
	) => void
}
